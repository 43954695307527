import { fb, validators } from "../../../form";
//import utils from '../../../utils';

export function getShippingFormData(cart) {
  const data = cart || {};

  console.log("data.pickupDate", data.pickupDate);

  const shippingMethods = [
    { name: "Delivery", value: "Standard" },
    { name: "Pickup", value: "Pickup" },
  ];

  const shippingCountry = [
    { name: "Australia", value: "Australia" },
    { name: "International", value: "International" },
  ];

  const locationList = [
    { name: "Cafe de Vili’s (Mile End)", value: "Cafe de Vili’s (Mile End)" },
    // { name: 'Bunnings Parafield', value: "Bunnings Parafield" },
    // { name: 'Bunnings Munno Para', value: "Bunnings Munno Para" },
    // { name: 'Bunnings Woodville', value: "Bunnings Woodville" },
    // { name: 'Bunnings Noarlunga', value: "Bunnings Noarlunga" },
    // { name: 'Bunnings Marion', value: "Bunnings Marion" },
    // { name: 'Bunnings Victor Harbor', value: "Bunnings Victor Harbor" }
  ];

  //const location = data.orderExtSerializedObj && data.orderExtSerializedObj.location ? data.orderExtSerializedObj.location : null;
  //const availableFirstDate = utils.date.formatYYYYMMDD(data.availableFirstDate);

  // const checkTodayFun = () => {
  //   if (data.deliveryPickupDate && data.deliveryPickupDate !== ''){
  //     let today = new Date(utils.date.formatYYYYMMDDToday()).getTime();
  //     let _deliveryPickupDate = new Date(data.deliveryPickupDate).getTime();
  //     if(_deliveryPickupDate === today){
  //       return true;
  //     }
  //   }
  //   return false;
  // }

  // const checkTodayFunT = () => {
  //   let time = new Date().toLocaleTimeString();
  //   let timeTokenizered = time.split(":");
  //   let hour = timeTokenizered[0];
  //   let min = timeTokenizered[1];

  //   if(parseInt(min) >= 30){
  //     hour = (parseInt(timeTokenizered[0]) + 1).toString();
  //     min = "0"
  //   }
  //   else{
  //     min = "30"
  //   }

  //   let startHour = (parseInt(hour) + 2) * 60 + (parseInt(min));
  //   let results = [{ name: 'Select Time', value: '' }];

  //   while( startHour <= 1410){

  //     let _value = (startHour / 60).toString().split(".");

  //     let _convertedHour = _value[0];
  //     let _convertedAMPM = "AM";
  //     if(_convertedHour >= 13){
  //       _convertedAMPM = "PM";
  //       _convertedHour = _convertedHour - 12;
  //     }

  //     let v = _convertedHour + ":" + (_value.length === 2 ? '30' : '00') + " " + _convertedAMPM;

  //     results.push({ name:  v, value: v });
  //     startHour = startHour + 30;

  //   }

  //   return results;
  // }

  //checkTodayFunT();

  //const availableFirstDate = utils.date.formatYYYYMMDDToday();

  //const pickupDate = data.orderExtSerializedObj && data.orderExtSerializedObj.pickupDate ? data.orderExtSerializedObj.pickupDate : null;
  //const pickupTime = data.orderExtSerializedObj && data.orderExtSerializedObj.pickupTime ? data.orderExtSerializedObj.pickupTime : null;

  // let pickupTimeList = [
  //   '12:00 AM',
  //   '12:30 AM',
  //   '01:00 AM',
  //   '01:30 AM',
  //   '02:00 AM',
  //   '02:30 AM',
  //   '03:00 AM',
  //   '03:30 AM',
  //   '04:00 AM',
  //   '04:30 AM',
  //   '05:00 AM',
  //   '05:30 AM',
  //   '06:00 AM',
  //   '06:30 AM',
  //   '07:00 AM',
  //   '07:30 AM',
  //   '08:00 AM',
  //   '08:30 AM',
  //   '09:00 AM',
  //   '09:30 AM',
  //   '10:00 AM',
  //   '10:30 AM',
  //   '11:00 AM',
  //   '11:30 AM',
  //   '12:00 PM',
  //   '12:30 PM',
  //   '01:00 PM',
  //   '01:30 PM',
  //   '02:00 PM',
  //   '02:30 PM',
  //   '03:00 PM',
  //   '03:30 PM',
  //   '04:00 PM',
  //   '04:30 PM',
  //   '05:00 PM',
  //   '05:30 PM',
  //   '06:00 PM',
  //   '06:30 PM',
  //   '07:00 PM',
  //   '07:30 PM',
  //   '08:00 PM',
  //   '08:30 PM',
  //   '09:00 PM',
  //   '09:30 PM',
  //   '10:00 PM',
  //   '10:30 PM',
  //   '11:00 PM',
  //   '11:30 PM',
  // ].map(x => { return { name: x, value: x } });

  let pickupTimeList = [
    "06:00 AM to 08:00 AM",
    "07:00 AM to 09:00 AM",
    "08:00 AM to 10:00 AM",
    "09:00 AM to 11:00 AM",
    "10:00 AM to 12:00 PM",
    "11:00 AM to 01:00 PM",
  ].map((x) => {
    return { name: x, value: x };
  });

  // pickupTimeList = [{name: 'Select Time', value: ''}, ...pickupTimeList];

  //pickupTimeList =  checkTodayFun() === false ? [{name: 'Select Time', value: ''}, ...pickupTimeList] : checkTodayFunT();
  pickupTimeList = [{ name: "Select Time", value: "" }, ...pickupTimeList];

  const deliveryList = [
    { name: "Select Date", value: "" },

    { name: "Tuesday 27th May 2025", value: "Tuesday 27th May 2025" },
    { name: "Wednesday 28th May 2025", value: "Wednesday 28th May 2025" },
    { name: "Thursday 29th May 2025", value: "Thursday 29th May 2025" },
    { name: "Friday 30th May 2025", value: "Friday 30th May 2025" },
    { name: "Saturday 31st May 2025", value: "Saturday 31st May 2025" },

    { name: "Monday 2nd June 2025", value: "Monday 2nd June 2025" },
    { name: "Tuesday 3rd June 2025", value: "Tuesday 3rd June 2025" },

    { name: "Wednesday 2nd July 2025", value: "Wednesday 2nd July 2025" },
    { name: "Thursday 3rd July 2025", value: "Thursday 3rd July 2025" },
    { name: "Friday 4th July 2025", value: "Friday 4th July 2025" },

    { name: "Tuesday 8th July 2025", value: "Tuesday 8th July 2025" },
    { name: "Wednesday 9th July 2025", value: "Wednesday 9th July 2025" },
    { name: "Thursday 10th July 2025", value: "Thursday 10th July 2025" },
    { name: "Friday 11th July 2025", value: "Friday 11th July 2025" },
    { name: "Saturday 12th July 2025", value: "Saturday 12th July 2025" },

    // { name: "Wednesday 3rd July", value: "Wednesday 3rd July" },
    // { name: "Thursday 4th July", value: "Thursday 4th July" },
    // { name: "Friday 5th July", value: "Friday 5th July" },
    // { name: "Saturday 6th July", value: "Saturday 6th July" },

    // { name: "Monday 8th July 2024", value: "Monday 8th July 2024" },
    // { name: "Tuesday 9th July 2024", value: "Tuesday 9th July 2024" },
    // { name: "Wednesday 10th July 2024", value: "Wednesday 10th July 2024" },
    // { name: "Thursday 11th July 2024", value: "Thursday 11th July 2024" },
    // { name: "Friday 12th July 2024", value: "Friday 12th July 2024" },
    // { name: "Saturday 13th July 2024", value: "Saturday 13th July 2024" },
  ];

  const convertDateToText = (date) => {
    //const _day = date.getDay();
    let _dayText = "";
    let _dateText = "";
    let _monthText = "";
    const _date = date.getDate();

    switch (date.getDay()) {
      case 0:
        _dayText = "Sunday";
        break;
      case 1:
        _dayText = "Monday";
        break;
      case 2:
        _dayText = "Tuesday";
        break;
      case 3:
        _dayText = "Wednesday";
        break;
      case 4:
        _dayText = "Thursday";
        break;
      case 5:
        _dayText = "Friday";
        break;
      case 6:
        _dayText = "Saturday";
        break;
      default:
        break;
    }

    switch (_date) {
      case 1:
        _dateText = "1st";
        break;
      case 2:
        _dateText = "2nd";
        break;
      case 3:
        _dateText = "3rd";
        break;
      case 21:
        _dateText = "21st";
        break;
      case 22:
        _dateText = "22nd";
        break;
      case 23:
        _dateText = "23rd";
        break;
      case 31:
        _dateText = "31st";
        break;
      default:
        _dateText = _date + "th";
        break;
    }

    switch (date.getMonth()) {
      case 0:
        _monthText = "January";
        break;
      case 1:
        _monthText = "February";
        break;
      case 2:
        _monthText = "March";
        break;
      case 3:
        _monthText = "April";
        break;
      case 4:
        _monthText = "May";
        break;
      case 5:
        _monthText = "June";
        break;
      case 6:
        _monthText = "July";
        break;
      case 7:
        _monthText = "August";
        break;
      case 8:
        _monthText = "September";
        break;
      case 9:
        _monthText = "October";
        break;
      case 10:
        _monthText = "November";
        break;
      case 11:
        _monthText = "December";
        break;
      default:
        break;
    }
    return (
      _dayText + " " + _dateText + " " + _monthText + " " + date.getFullYear()
    );
  };

  if (!localStorage.dumyDeliveryDate) {
    localStorage.setItem("dumyDeliveryDate", "");
  }

  const getDeliveryDate = () => {
    const dumyDeliveryDate = localStorage.getItem("dumyDeliveryDate");
    const _today = new Date();

    const startDate =
      dumyDeliveryDate && dumyDeliveryDate !== ""
        ? new Date(dumyDeliveryDate)
        : new Date(
            _today.getMonth() +
              1 +
              "/" +
              _today.getDate() +
              "/" +
              _today.getFullYear()
          );
    const orignStartDate =
      dumyDeliveryDate && dumyDeliveryDate !== ""
        ? new Date(dumyDeliveryDate)
        : new Date(
            _today.getMonth() +
              1 +
              "/" +
              _today.getDate() +
              "/" +
              _today.getFullYear()
          );

    const orignStartDate2 =
      dumyDeliveryDate && dumyDeliveryDate !== ""
        ? new Date(dumyDeliveryDate)
        : new Date(
            _today.getMonth() +
              1 +
              "/" +
              _today.getDate() +
              "/" +
              _today.getFullYear()
          );

    let checkVaildDate = startDate instanceof Date && !isNaN(startDate);

    if (checkVaildDate === false) {
      return deliveryList;
    }

    // const abc =
    //   orignStartDate2.getMonth() +
    //   1 +
    //   "/" +
    //   orignStartDate2.getDate() +
    //   "/" +
    //   orignStartDate2.getFullYear();

    let _minDate = new Date("5/25/2025");
    //let _minDate = new Date("5/22/2025");
    //const _juneMinDate = new Date("6/1/2023");
    const _juneMaxDate = new Date("6/3/2025");
    const _julyMinDate = new Date("7/3/2025");
    const _maxDate = new Date("7/13/2025");
    const _lasMaxDate = new Date("7/11/2025");

    startDate.setDate(startDate.getDate() + 3);
    orignStartDate.setDate(orignStartDate.getDate() + 3);

    //console.log("startDate", startDate);
    //console.log("_minDate", _minDate);
    //console.log("_today", new Date((_today.getMonth() + 1) + '/' + _today.getDate() + '/' + _today.getFullYear()));

    if (startDate <= _minDate) {
      return deliveryList;
    }

    let _deliveryList = [];

    _deliveryList.push({ name: "Select Date", value: "" });

    let _convertDateToText = "";
    let checkLoop = true;

    while (checkLoop) {
      if (startDate.getDay() === 0) {
        startDate.setDate(startDate.getDate() + 1);
      }

      if (
        startDate <= _juneMaxDate ||
        (startDate >= _julyMinDate && startDate <= _maxDate)
      ) {
        // if (abc === "5/24/2024") {
        //   if (
        //     avv === "5/27/2024" ||
        //     avv === "5/28/2024" ||
        //     avv === "5/29/2024"
        //   ) {
        //   } else {
        //     _convertDateToText = convertDateToText(startDate);
        //     _deliveryList.push({
        //       name: _convertDateToText,
        //       value: _convertDateToText,
        //     });
        //   }
        // } else if (abc === "5/28/2024") {
        //   if (
        //     avv === "5/30/2024" ||
        //     avv === "5/31/2024" ||
        //     avv === "6/1/2024" ||
        //     avv === "6/3/2024"
        //   ) {
        //   } else {
        //     _convertDateToText = convertDateToText(startDate);
        //     _deliveryList.push({
        //       name: _convertDateToText,
        //       value: _convertDateToText,
        //     });
        //   }
        // } else {
        // }

        if (orignStartDate2 >= new Date("5/24/2025") && orignStartDate2 <= new Date("5/25/2025")) {
          if (startDate >= new Date("5/30/2025")) {
            _convertDateToText = convertDateToText(startDate);
            _deliveryList.push({
              name: _convertDateToText,
              value: _convertDateToText,
            });
          }
        }
        else if (orignStartDate2 >= new Date("5/28/2025")) {
          if (startDate >= new Date("7/3/2025")) {
            _convertDateToText = convertDateToText(startDate);
            _deliveryList.push({
              name: _convertDateToText,
              value: _convertDateToText,
            });
          }
        } else {
          _convertDateToText = convertDateToText(startDate);
          _deliveryList.push({
            name: _convertDateToText,
            value: _convertDateToText,
          });
        }
      }

      if (startDate >= _maxDate) {
        checkLoop = false;
      }
      startDate.setDate(startDate.getDate() + 1);
    }

    if (_deliveryList.length === 1 && orignStartDate >= _lasMaxDate) {
      //0
      if (orignStartDate.getDay() === 0) {
        orignStartDate.setDate(orignStartDate.getDate() + 1);
      }
      _convertDateToText = convertDateToText(orignStartDate);
      _deliveryList.push({
        name: _convertDateToText,
        value: _convertDateToText,
      });
    }

    return _deliveryList;
  };

  const model = fb.group({
    billingAddress: address(data.billingAddress),
    shippingAddress: address(data.shippingAddress),
    //shippingMethod: [data.shippingMethod || '', [], { label: 'Shipping Method', type: 'text' }],
    shippingMethod: [
      data.shippingMethod || "",
      [],
      {
        label: null,
        type: "radioList",
        options: shippingMethods,
        style: { inline: true },
      },
    ],
    shippingCountry: [
      data.shippingCountry || "Australia",
      [],
      {
        label: null,
        type: "radioList",
        options: shippingCountry,
        style: { inline: true },
      },
    ],
    useSameAddress: [
      data.useSameAddress || false,
      [],
      { label: `Use the same address`, type: "checkbox" },
    ],
    //deliveryPickupDate: [data.deliveryPickupDate || '', [validators.Required()], { label: null, type: 'select', options: deliveryList }],
    deliveryPickupDate: [
      data.deliveryPickupDate || "",
      [validators.Required()],
      { label: null, type: "select", options: getDeliveryDate() },
    ],
    deliveryPickupTime: [
      data.deliveryPickupTime || "",
      [data.shippingMethod === "Standard" && validators.Required()],
      { label: null, type: "select", options: pickupTimeList },
    ],
    location: [
      data.location || "Cafe de Vili’s (Mile End)",
      [data.shippingMethod === "Pickup" && validators.Required()],
      { label: null, type: "select", options: locationList },
    ],
    deliveryInstructions: [
      data.deliveryInstructions || "",
      [],
      { label: null, type: "textarea" },
    ],
  });
  return model;
}

export function address(model) {
  const data = model || {};
  return fb.group({
    firstName: [
      data.firstName || "",
      [validators.Required()],
      { label: "First Name", type: "text" },
    ],
    surname: [
      data.surname || "",
      [validators.Required()],
      { label: "Surname", type: "text" },
    ],
    street1: [
      data.street1 || "",
      [validators.Required()],
      { label: "Street", type: "text" },
    ],
    street2: [data.street2 || "", [], { label: null, type: "text" }],
    suburb: [
      data.suburb || "",
      [validators.Required()],
      { label: "Suburb", type: "text" },
    ],
    state: [
      data.state || "",
      [validators.Required()],
      { label: "State", type: "text" },
    ],
    postcode: [
      data.postcode || "",
      [validators.Required()],
      { label: "Postcode", type: "text" },
    ],
    email: [
      data.email || "",
      [validators.Required(), validators.Email()],
      { label: "Email Address", type: "email" },
    ],
    mobile: [
      data.mobile || "",
      [validators.Required()],
      { label: "Mobile", type: "tel" },
    ],
    businessName: [
      data.businessName || "",
      [],
      { label: "Business Name", type: "text" },
    ],
    abn: [data.abn || "", [], { label: "ABN", type: "text" }],
  });
}
