/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Fragment } from 'react';
//import style from './shippingStyle';
import { Button, ErrorMessage } from '../../../components';
import { CartSummary } from '../cartSummary/summary';
//import utils from '../../../utils';

export function Shipping({ cart, form, saveShipping, post }) {
  const model = form.model;
  const render = (name) => form.renderControl(name);

  const val = form.getValue();
  const billingAddress = model.find('billingAddress');
  const shippingAddress = model.find('shippingAddress');
  
  const next = (e) => {
    form.validateForm(e, () => {
      saveShipping();
      //utils.ui.scrollTo('#ecmcart');
    })
  }

  if (billingAddress) {
    billingAddress.disableValidators = val.useSameAddress;
  }

  shippingAddress.disableValidators = false;

  if (val.shippingMethod === 'Pickup' || val.shippingMethod === '0') {
    shippingAddress.disableValidators = true;
  }

  console.log("val", val);

  return <div>
    <div className="row" >
      <div className="col-md-9">
        <form>
          <h3>Delivery Method</h3>
          {render('shippingMethod')}
          {
            val.shippingMethod !== '' &&
            <Fragment>
              {
                val.shippingMethod === 'Standard' && cart.cartInfo.totalQuantity >= 50 ?
                <Fragment>
                  <h3> Delivery Date & Time</h3>
                  <div className="row">
                    <div className='col-12 col-md-6'>
                      {render('deliveryPickupDate')}
                      {/* <div style={{fontSize : '0.8rem'}}>only available : Friday 26th May - Saturday 3rd June, Sunday 4th July - Sunday 11th July</div> */}
                    </div>
                    <div className='col-12 col-md-6'>
                      {render('deliveryPickupTime')}
                    </div>
                  </div>

                  <h3> Enter Delivery Instructions</h3>
                  {render('deliveryInstructions')}

                  <h3>Shipping Address</h3>
                  <Address form={form} addressModel={shippingAddress} />
                </Fragment>
                :
                <Fragment>
                  <p style={{ color: 'red' }}>A minimum of 50+ items is required for delivery.</p>
                </Fragment>
              }

              {
                val.shippingMethod === 'Pickup' &&
                <Fragment>

                  <h3> Pickup Date & Location </h3>
                  
                  <div className="row">
                    <div className='col-12 col-md-6'>
                      {render('deliveryPickupDate')}
                      {/* <div style={{fontSize : '0.8rem'}}>only available : Friday 26th May - Saturday 3rd June, Sunday 4th July - Sunday 11th July</div> */}
                    </div>
                    <div className='col-12 col-md-6'>
                      {render('location')}
                    </div>
                  </div>

                </Fragment>
              }

              <h3>Billing / Business Address</h3>
              {
                val.shippingMethod === 'Standard' && cart.cartInfo.totalQuantity >= 50 &&
                <Fragment>
                  {render('useSameAddress')}
                </Fragment>
              }
              {
                !val.useSameAddress &&
                <Address2 form={form} addressModel={billingAddress} />
              }
            </Fragment>
          }

        </form>
      </div>

      <div className="col-md-3">
        <CartSummary cart={cart} />
      </div>
    </div>

    {
      val.shippingMethod !== '' &&
      <Fragment>
        <div className="form__actions">
          <Button onClick={next} status={form.status}>Next</Button>
          <ErrorMessage errors={form.errors} summaryMessage="Please review the errors." />
          <ErrorMessage errors={post.errors} />
        </div>
      </Fragment>
    }

  </div>
}

function Address({ form, addressModel }) {
  const render = (name) => form.renderControl(name, addressModel);
  return <div>
    <div className="row">
      <div className="col-12 col-md-6">
        {render('firstName')}
      </div>
      <div className="col-12 col-md-6">
        {render('surname')}
      </div>
    </div>
    <div className="row">
      <div className="col-12 col-md-6">
        {render('businessName')}
      </div>
      <div className="col-12 col-md-6">
        {render('abn')}
      </div>
    </div>
    <div className="row">
      <div className="col-12">
        {render('street1')}{render('street2')}
      </div>
    </div>
    <div className="row">
      <div className="col-12 col-md-4">
        {render('suburb')}
      </div>
      <div className="col-12 col-md-4">
        {render('state')}
      </div>
      <div className="col-12 col-md-4">
        {render('postcode')}
      </div>
    </div>
    <div className="row">
      <div className="col-12 col-md-6">
        {render('email')}
      </div>
      <div className="col-12 col-md-6">
        {render('mobile')}
      </div>
    </div>
  </div>
}

function Address2({ form, addressModel }) {
  const render = (name) => form.renderControl(name, addressModel);
  return <div>
    <div className="row">
      <div className="col-12 col-md-6">
        {render('firstName')}
      </div>
      <div className="col-12 col-md-6">
        {render('surname')}
      </div>
    </div>
    <div className="row">
      <div className="col-12 col-md-6">
        {render('businessName')}
      </div>
      <div className="col-12 col-md-6">
        {render('abn')}
      </div>
    </div>
    <div className="row">
      <div className="col-12">
        {render('street1')}{render('street2')}
      </div>
    </div>
    <div className="row">
      <div className="col-12 col-md-4">
        {render('suburb')}
      </div>
      <div className="col-12 col-md-4">
        {render('state')}
      </div>
      <div className="col-12 col-md-4">
        {render('postcode')}
      </div>
    </div>
    <div className="row">
      <div className="col-12 col-md-6">
        {render('email')}
      </div>
      <div className="col-12 col-md-6">
        {render('mobile')}
      </div>
    </div>
  </div>
}